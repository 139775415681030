import {YN, STATUS, CLIENT, ClientTimeUnit} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import {setSelectClientList, setSelectOwnerList, setSkuConfigIdList} from "../../../../api/common";
import {PAGER} from "../../../../constant/config";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "SKU_ID",
      "label": "SKU",
      "type": "magnifier",
      "placeholder": "请输入SKU",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    },{
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": [
        "dataInfo"
      ],
      "col": "2",
    }, {
      "key": "TRACKING_LEVEL",
      "label": "跟踪级别 ",
      "type": "multiselect",
      "placeholder": "请选择跟踪级别 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": []
    }, {
      "key": "CONFIG_ID",
      "label": "打包配置",
      "type": "multiselect",
      "placeholder": "请选择打包配置",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": async () => {
        /*TODO 联动CLIENT_ID，SKU_ID 查询*/
        let {status, data: {list}, message} = await setSkuConfigIdList({
          CLIENT_ID:'',
          SKU_ID:'',
          ...defaultPaginationInfo
        });
        if (Number(status) === 200) {
          return list.map(item=>{
            return{
              key:item.CONFIG_ID,
              label:item.CONFIG_ID
            }
          })
        }
      },
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      // "options": async () => {
      //   let {status, data: {list}, message} = await setSelectOwnerList({
      //     WORKSTATION: {},
      //     ...defaultPaginationInfo
      //   });
      //
      //   if (Number(status) === 200) {
      //     return list.map(item=>{
      //       return{
      //         key:item.OWNER_ID,
      //         label:item.OWNER_ID
      //       }
      //     })
      //   }
      // },
    }, {
      "key": "QTY_ORDERED",
      "label": "订单数 ",
      "type": "numrange",
      "placeholder": "订单数 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MIN_QTY_ORDERED",
      "label": "最小订货数量 ",
      "type": "numrange",
      "placeholder": "请选择最小订货数量 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MAX_QTY_ORDERED",
      "label": "最大订货数量 ",
      "type": "numrange",
      "placeholder": "请选择最大订货数量 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_TASKED",
      "label": "分派任务数",
      "type": "numrange",
      "placeholder": "分派任务数",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_SOFT_ALLOCATED",
      "label": "预分配数量",
      "type": "numrange",
      "placeholder": "请选择预分配数量",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_PICKED",
      "label": "拣选数量  ",
      "type": "numrange",
      "placeholder": "请选择拣选数量 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CUSTOMER_SKU_ID",
      "label": "客户SKU",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入客户SKU",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_SHIPPED",
      "label": "已运送数 ",
      "type": "numrange",
      "placeholder": "请选择已运送数",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_DELIVERED",
      "label": "交货数量 ",
      "type": "numrange",
      "placeholder": "请选择交货数量 ",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "QTY_RETURNED",
      "label": "退货数量",
      "type": "numrange",
      "placeholder": "请选择退货数量",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SPEC_CODE",
      "label": "规格代码",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入规格代码",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ORIGINAL_LINE_ID",
      "label": "原线程ID",
      "type": "numrange",
      "placeholder": "请选择原线程ID",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ALLOCATE",
      "label": "分配",
      "type": "select",
      "placeholder": "请选择分配",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "HOST_ORDER_ID",
      "label": "主单号码",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入主单号码",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "HOST_LINE_ID",
      "label": "主单行号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入主单行号",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "BACK_ORDERED",
      "label": "延期交货",
      "type": "select",
      "placeholder": "请选择延期交货",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SOFT_ALLOCATED",
      "label": "是否预分配",
      "type": "select",
      "placeholder": "请选择是否预分配",
      "tabGroup": "mqs_orderline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "RULE_ID",
      "label": "规则ID",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入规则ID",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LINE_VALUE",
      "label": "行价值 ",
      "type": "numrange",
      "placeholder": "请选择行价值 ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "KIT_SPLIT",
      "label": "拆分套件",
      "type": "select",
      "placeholder": "请选择拆分套件",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "LOCK_CODE",
      "label": "锁定代码",
      "type": "magnifier",
      "placeholder": "请输入锁定代码",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "BATCH_ID",
      "label": "批次",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入批次",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "BATCH_MIXING",
      "label": "批次混合",
      "type": "select",
      "placeholder": "请选择批次混合",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SHELF_LIFE_DAYS",
      "label": "保质期天数",
      "type": "numrange",
      "placeholder": "请选择保质期天数",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SHELF_LIFE_PERCENT",
      "label": "保质期百分比",
      "type": "numrange",
      "placeholder": "请选择保质期百分比",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USE_PICK_TO_GRID",
      "label": "忽略的行值",
      "type": "select",
      "placeholder": "请选择忽略的行值",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ORIGIN_ID",
      "label": "来源",
      "type": "magnifier",
      "placeholder": "请输入来源",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CONDITION_ID",
      "label": "条件 ",
      "type": "magnifier",
      "placeholder": "条件 ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CATCH_WEIGHT",
      "label": "捕获重量 ",
      "type": "numrange",
      "placeholder": "请选择捕获重量 ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "IGNORE_WEIGHT_CAPTURE",
      "label": "忽略获取重量",
      "type": "select",
      "placeholder": "忽略获取重量",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TASK_PER_EACH",
      "label": "单个移动任务",
      "type": "select",
      "placeholder": "请选择单个移动任务",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "STAGE_ROUTE_ID",
      "label": "分段路线ID ",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入分段路线ID ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LOCATION_ID",
      "label": "位置",
      "type": "magnifier",
      "placeholder": "请输入位置",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATED_BY",
      "label": "创建人 ",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入创建人 ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期 ",
      "type": "date",
      "placeholder": "请选择创建日期 ",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择创建时间",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最新更新人",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入最新更新人",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最新更新日期",
      "type": "date",
      "placeholder": "请选择最新更新日期",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最新更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最新更新时间",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "LINE_VALUE_USER_DEF",
      "label": "Line Value Override",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入Line Value Override",
      "tabGroup": "mqs_orderline_tab1",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CUSTOMER_SKU_DESC1",
      "label": "客户货品描述1 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入客户货品描述1 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CUSTOMER_SKU_DESC2",
      "label": "客户货品描述2 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入客户货品描述2 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRODUCT_PRICE",
      "label": "Product Price",
      "type": "numrange",
      "placeholder": "Product Price",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PRODUCT_CURRENCY",
      "label": "Product Currency ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "Product Currency",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXTENDED_PRICE",
      "label": "总价",
      "type": "numrange",
      "placeholder": "请选择总价",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "TAX_1",
      "label": "税额1",
      "type": "numrange",
      "placeholder": "请选择税额1",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "TAX_2",
      "label": "税额2",
      "type": "numrange",
      "placeholder": "请选择税额2",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PURCHASE_ORDER",
      "label": "采购单",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入采购单",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "DOCUMENTATION_UNIT",
      "label": "单据项目",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入单据项目",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "DOCUMENTATION_TEXT_1",
      "label": "单据文本 1 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入单据文本 1 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "KIT_PLAN_ID",
      "label": "套装计划ID",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入套装计划ID",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXPECTED_WEIGHT",
      "label": "预计重量 ",
      "type": "numrange",
      "placeholder": "请选择预计重量 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXPECTED_VALUE",
      "label": "预计价值 ",
      "type": "numrange",
      "placeholder": "请选择预计价值 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "EXPECTED_VOLUME",
      "label": "预计体积 ",
      "type": "numrange",
      "placeholder": "请选择预计体积 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MASTER_ORDER_ID",
      "label": "主订单",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入主订单",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MASTER_ORDER_LINE_ID",
      "label": "主订单行",
      "type": "numrange",
      "placeholder": "请选择主订单行",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CONVERSION_FACTOR",
      "label": "转换因素 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入转换因素 ",
      "tabGroup": "mqs_orderline_tab2",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SUBSTITUTE_FLAG",
      "label": "可选替代品",
      "type": "select",
      "placeholder": "请选择可选替代品",
      "tabGroup": "mqs_orderline_tab3",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "QTY_SUBSTITUTED",
      "label": "替代数量",
      "type": "numrange",
      "placeholder": "请选择替代数量",
      "tabGroup": "mqs_orderline_tab3",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SERIAL_NUMBER",
      "label": "序列号 ",
      "type": "magnifier",
      "tableName":'ORDER_LINE',
      "placeholder": "请输入序列号 ",
      "tabGroup": "mqs_orderline_tab3",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "客户单号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入客户单号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "客户ID",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "客户ID",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "客户订单号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "客户订单号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "ETQ客户单号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "ETQ客户单号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "工厂号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "工厂号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "集装箱号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "集装箱号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "虚拟库位号",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "虚拟库位号",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "CIQ No.",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "CIQ No.",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "今日订单",
      "type": "select",
      "tableName": "ORDER_LINE",
      "placeholder": "今日订单",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "select",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "select",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "select",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "用户自定义时间 1 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 1 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "用户自定义时间 2 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间2 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "用户自定义时间 3 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 3 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "用户自定义时间 4 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 4 ",
      "tabGroup": "user_def_ol_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "user_def_ol_tab1",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ORDER_ID",
      "label": "订单",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入订单",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CLIENT_ID",
      "label": "客户端ID",
      "type": "magnifier",
      "placeholder": "请选择客户端ID",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "line"
    }, {
      "key": "LINE_ID",
      "label": "行ID",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入行ID",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "NOTES",
      "label": "注释",
      "type": "textarea",
      "placeholder": "请输入注释",
      "tabGroup": "THead",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "col": "2",
    }, {
      "key": "ALLOC_SESSION_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "ALLOC_STATUS",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "BATCH_ID_SET",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_COO",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "CE_RECEIPT_TYPE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "DEALLOCATE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "FULL_TRACKING_LEVEL_ONLY",
      "label": "",
      "type": "select",
      "placeholder": "请选择",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MAX_FULL_PALLET_PERC",
      "label": "最大订单数量百分比",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入最大订单数量百分比",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "MIN_FULL_PALLET_PERC",
      "label": "最小订货数量百分比",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入最小订货数量百分比",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PSFT_DMND_LINE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PSFT_INT_LINE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "PSFT_SCHD_LINE",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "SAP_PICK_REQ",
      "label": "Sap 拣货需求 ",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入Sap 拣货需求 ",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "TM_SHIP_LINE_ID",
      "label": "",
      "type": "autoComplete",
      "tableName": "ORDER_LINE",
      "placeholder": "请输入",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line"
    }, {
      "key": "UNALLOCATABLE",
      "label": "不允许分配",
      "type": "select",
      "placeholder": "请选择不允许分配",
      "tabGroupName": "综合",
      "showBlock": [],
      "checked": false,
      "sheetName": "line",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }]
  }
}